import style from "./header.module.css";
import { Link } from "react-location";
import { AppRoute } from "const/const";

const Header = () => {
  return (
    <header className={style["header"]}>
      <div className={style["header__wrapper"]}>
        <nav className={style["nav"]}>
          <ul className={style["nav-list"]}>
            <li className={style["nav-list__item"]}>
              <Link
                to={AppRoute.Home}
                getActiveProps={() => ({
                  className: style["nav-list__link--active"],
                })}
                className={style["nav-list__link"]}
              >
                Home
              </Link>
            </li>
            <li className={style["nav-list__item"]}>
              <Link
                to={AppRoute.Price}
                getActiveProps={() => ({
                  className: style["nav-list__link--active"],
                })}
                className={style["nav-list__link"]}
              >
                Prices
              </Link>
            </li>
            <li className={style["nav-list__item"]}>
              <Link
                to={AppRoute.Gallery}
                getActiveProps={() => ({
                  className: style["nav-list__link--active"],
                })}
                className={style["nav-list__link"]}
              >
                Examples
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
