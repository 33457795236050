import { AppRoute } from "const/const";
import { Link } from "react-location";
import style from "./home-page.module.css";
// import { ReactComponent as VkIcon } from "assets/icons/icon-vk.svg";
import { ReactComponent as TgIcon } from "assets/icons/icon-tg.svg";
import { ReactComponent as DiscordIcon } from "assets/icons/icon-discord.svg";

const HomePage = () => {
  return (
    <main className={style["page-wrapper"]}>
      <h1 className="visually-hidden">Art by Dictator.Fleki</h1>
      <section className={style["hero"]}>
        <div className={style["hero-avatar"]}>
          <img src="/image/avatar.jpg" alt="My art" />
        </div>
        <article className={style["about"]}>
          {/* <h2 className={style["about__header"]}>Hello!</h2> */}
          <img
            className={style["about__header--image"]}
            src="/image/logo.png"
            alt="Fleki art"
          />
          <div className={style["about__text"]}>
            <p>
              Hi! It's me Dictator, but when I do art, I go by Fleki.
            </p>
            <p>
              I'm a freelance artist from Kazakhstan, I create art for games,
              movies, books and also for individuals.
            </p>

            <p style={{ margin: "0" }}>
              I have more than 20 years of experience and over 1700 finished
              artworks. If you wanna work with me, check out my prices and
              contact with me via Telegram or Discord{" "}
            </p>
          </div>
          <span className={style["copyright"]}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/VladimirDyachenko"
            >
              Site by hwh4p
            </a>{" "}
            | Guest Art by Koul Fardreamer
          </span>
        </article>
        <section className={style["navigation"]}>
          <nav>
            <h2 className={style["navigation__title"]}>Navigation</h2>
            <ul className={style["navigation__list"]}>
              <li>
                <Link to={AppRoute.Price} className={style["navigation__link"]}>
                  Prices
                </Link>
              </li>
              <li>
                <Link
                  to={AppRoute.Gallery}
                  className={style["navigation__link"]}
                >
                  Examples
                </Link>
              </li>
            </ul>
          </nav>
          <section>
            <h2 className={style["navigation__title"]}>Contacts</h2>

            <ul className={`${style["navigation__list"]} ${style["navigation__list--center"]}`}>
              {/* <li className={style["icon-link"]}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.patreon.com/fleki"
                  className={style["navigation__link navigation__link--icon"]}
                >
                  <VkIcon className={style["icon"]} />
                  <span className="visually-hidden">Patreon</span>
                </a>
              </li> */}
              <li className={style["icon-link"]}>
                <a
                  href="tg://resolve?domain=Dictator_Fleki"
                  className={style["navigation__link navigation__link--icon"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TgIcon className={style["icon"]} />
                  <span className="visually-hidden">Telegram</span>
                </a>
              </li>
              <li className={style["icon-link"]}>
                <a
                  href="https://discord.gg/uYWzZt54n3"
                  className={style["navigation__link navigation__link--icon"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <DiscordIcon className={style["icon"]} />
                  <span className="visually-hidden">Discord</span>
                </a>
              </li>
            </ul>
          </section>
        </section>
      </section>
    </main>
  );
};

export default HomePage;
