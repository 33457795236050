import HolyGrail from "components/common/layout/holy-grail/holy-grail";
import { AppRoute } from "const/const";
import { Link } from "react-location";
import { ProductsList } from "./components/components";
import style from "./prices-page.module.css";

const ContactsPage = () => {
  return (
    <HolyGrail>
      <h1 className={style["page-heading"]}>Prices Page</h1>
      <ProductsList />

      <section className={style["page-order"]}>
        <h2>
          Make sure to read{" "}
          <Link className={style["link"]} to={AppRoute.TOS}>
            TOS
          </Link>
        </h2>

        <p>
          If you wanna work with me, contact with me via {" "}
          {<a href="tg://resolve?domain=Dictator_Fleki" className={style['external-link']} target="_blank" rel="noopener noreferrer">Telegram</a>} or Discord (@dictator.)
        </p>
      </section>
    </HolyGrail>
  );
};

export default ContactsPage;
