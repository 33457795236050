export type TOS = {
  sectionTitle: string;
  line: string[];
}

export const tosList: ReadonlyArray<TOS> = [
  {
    sectionTitle: 'Descriptions',
    line: [
      'If it’s possible, send your description in a very first note, it will save time for us both.',
      'I DO NOT work with written descriptions of a characters, unless it’s really simple ones.',
      'Reference Sheet is always welcome, but not necessary, just random pictures of your character are fine too.',
      'If you have photos/art of poses, expressions, backgrounds you are looking for - it will be useful for me.',
      'For your own good and best experience, please, don’t be over descriptive. You can be, you can ask for exact poses with pointing me where exactly every arm and tail should be, but as an artist I can tell, that more freedom you give to an artist, better picture will be. It’s always better to say “I want Char A standing behind Char B and hugging him from behind, with his head on Char’s A shoulder”, than describing everything. You may not like what you see in the end, I can tell.',
      'Please, tell me if it’s necessary to have all of your character fully visible. It is possible, but there is not that many ways to show characters like that. Sure, for your money you want to SEE IT ALL, but best angles, best ideas may require to put some body parts of your character behind the picture frame. It is artistic, it will make your commission a piece of art, not just another solo standing shot, like thousands of others. I can do it, and I know that for some proposes this simple picture will fit, but not all the time. It’s just an advice, not a rule.',
      'You can change your description whenever you want before I started working on your commission, don’t be shy to write to me.',
    ],
  },
  {
    sectionTitle: 'Final Price and Payments',
    line: [
      'Prices usually do not change from what you see in my price list. What may affect on price rising?',
     'If your char has additional hands, legs or wings.',
     'If you ask for changes after flat coloring',
     'After discussion about your commission, I will invoice you via PayPal and put you on my todo list on Trello (I will share a private link with you).',
     'I take Full Payment upfront via PayPal only (More payment options coming soon, hopefully)',
    ]
  },
  {
    sectionTitle: 'After Payment, Waiting for a Picture',
    line: [
      'When I take your commission, I usually tell you when it will be ready, mostly I say "This month", because I have quick turnaround but I don’t wanna have exact dates, cos life is life. Usually waiting is not longer than 3 weeks, you can see yourself how many commissions I have before yours via link to Trello I share with you after payment. Numbers next to a name is a date when commissions was paid. I start from ones who paid first. I usually do 1 fullcolor commission within a couple of days, so you can do the math.',
      'I can’t promise you anything like a strict deadline with it, life might get busy with a cons and so on, but I’m trying to finish all of my paid commissions before I’m gone for vacations or that kind of things.',
      'If you have a deadline, like a birthday or something like that, please, tell me about it when we\'re talking about your commission, before making a payment and I will tell if I can make it or not.',
      'If I said that I will finish your picture till your deadline, then I will do or I refund you 100%. No other way around.',
      'If you tell me about deadline in any time after discussing everything and sending your payment to me, the only thing I can do is to try. Usually I do my best to help you, but since you didn’t told it at the first place, there can be a time problems. But I’m always doing my best to help you.',
      'Please tell me, if you wanna see a WIP of your picture. If you trust me – then just wait for result. If you want to be sure – I will send you a sketch, here is no problem, just remember, since I have 13 hours time difference with US, it will turn into a few extra days waiting for your commission.',
      'I will make free changes only if I drew something which is against your description. For example, you asked characters sitting and hugging, but I drew them standing and hugging.',
      'I will make changes to make it look not like your first description, but you will have to pay extra.',
      'Just remember, it never will look just like in your head. It never even looks like in MY head XD',
      'When picture is done, I will post it in my gallery and drop you a note with a link to high resolution picture.',
      'I don’t give away my PSD or SAI files as it is, I merge layers in it, but character can be separated from the background. You may ask it if you need.',
      'You may ask me to not post your picture for public, which will cost you extra, because every picture brings me new watchers and customers'
    ],
  },
  {
    sectionTitle: 'Rights',
    line: [
      'I keep all commercial rights for your commissions. Means, I can print it and sell as posters and other merchandise products, upload in my galleries and portfolios. No worries, I will not resell your picture, will not do recolors.',
      'You have all rights on your picture for non-commercial use. You can reupload it, make icons and badges out of it, print posters for your own and your friends. You can’t sell this products.',
      'If you want commercial rights for it, ask me and we will talk about price, usually it\'s x2 from the regular price.',
      'You have a right for REFUND if I didn’t started working on your commission yet. Since I started, refund isn’t possible in any way.',
      'I have a right to decline your commission if I dislike something about it. Nothing personal.',
    ]
  }
];