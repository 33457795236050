import styles from "./products-list.module.css";
import ImageViewer from "react-simple-image-viewer";
import { products } from "const/products";
import { useCallback, useState } from 'react';

const ProductsList = (): JSX.Element => {
  const [imageUrl, setImageUrl] = useState<string[]>([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((url: string) => {
    setImageUrl([url]);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setImageUrl([]);
    setIsViewerOpen(false);
  };

  return (
    <>
      <section className={styles["products"]}>
        {products.map((product) => {
          return (
            <article
              key={product.heading}
              className={`${styles["products__item"]} ${styles["item"]}`}
            >
              <h2 className={styles["item__heading"]}>{product.heading}</h2>
              <img
                className={styles["item__image"]}
                src={product.image}
                alt="Product"
                onClick={() => openImageViewer(product.fullImage)}
              />
              {product.description.map((string) => (
                <p key={string} className={styles["item__description"]}>
                  {string}
                </p>
              ))}

              <p className={styles["item__special"]}>
                {product.priceConstructor.startOfLine ?? ""}

                {product.priceConstructor.prices.map((price, index) =>
                  index === product.priceConstructor.prices.length - 1 ? (
                    <span key={price} className={styles["item__highlight"]}>
                      {price}
                    </span>
                  ) : (
                    <span key={price}>
                      <span className={styles["item__highlight"]}>
                        {price}
                      </span>
                      {product.priceConstructor.joiner}
                    </span>
                  )
                )}

                {product.priceConstructor.endOfLine ?? ""}
              </p>

              {product.specialOffer && (
                <p className={styles["item__price"]}>{product.specialOffer}</p>
              )}
            </article>
          );
        })}
      </section>

      {isViewerOpen && (
        <ImageViewer
          src={imageUrl}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            zIndex: 10000,
            backgroundColor: "rgba(0, 0, 0, 0.95)",
          }}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default ProductsList;
