import { PropsWithChildren } from 'react';
import Header from 'components/common/header/header';
import Footer from 'components/common/footer/fotter';
import style from './holy-grail.module.css';

type HolyGrailProps = PropsWithChildren<{}>;

const HolyGrail = ({ children }: HolyGrailProps): JSX.Element => {
  return (
    <div className={style['wrapper']}>
      <Header/>
        <main  className={style['main']}>
          <div className={style['main__wrapper']}>
            {children}
          </div>
          <Footer />
        </main>
    </div>
  );
};

export default HolyGrail;
