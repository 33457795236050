import './App.css';
import { Outlet, ReactLocation, Router } from 'react-location';
import HomePage from './components/pages/home/home-page';
import ContactsPage from './components/pages/prices-page/prices-page';
import { AppRoute } from 'const/const';
import TosPage from 'components/pages/tos-page/tos-page';
import ExamplePage from 'components/pages/examples-page/examples-page';

const location = new ReactLocation();

function App() {
  return (
    <Router
      location={location}
      routes={[
        {
          path: AppRoute.Home,
          element: <HomePage />
        },
        {
          path: AppRoute.Price,
          element: <ContactsPage />
        },
        {
          path: AppRoute.Gallery,
          element: <ExamplePage />
        },
        {
          path: AppRoute.TOS,
          element: <TosPage />
        },
        {
          path: '*',
          element: <HomePage />
        },
      ]}
    >
      <Outlet />
    </Router>
  );
}

export default App;
