import HolyGrail from "components/common/layout/holy-grail/holy-grail";
import { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import styles from "./examples-page.module.css";

const images = [
  "/image/examples/1361336714.jpg",
  "/image/examples/1486987545.jpg",
  "/image/examples/1563211927.jpg",
  "/image/examples/curvypinup.jpg",
  "/image/examples/mattmartialart.jpg",
  "/image/examples/1549405474.jpg",
];

const ExamplePage = (): JSX.Element => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <HolyGrail>
      <h2 className="visually-hidden">Gallery</h2>
      <section className={styles["examples__gallery"]}>
        {images.map((image, index) => (
          <div className={styles["examples__image-container"]} key={image}>
            <img
              className={styles["examples__image"]}
              src={image}
              alt="Art by Fleki"
              onClick={() => openImageViewer(index)}
            />
          </div>
        ))}
      </section>

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            zIndex: 10000,
            backgroundColor: "rgba(0, 0, 0, 0.95)",
          }}
          onClose={closeImageViewer}
        />
      )}
    </HolyGrail>
  );
};

export default ExamplePage;
