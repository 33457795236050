export type Product = {
  heading: string;
  description: string[];
  specialOffer?: string;
  image: string;
  fullImage: string;
  priceConstructor: {
    prices: string[];
    joiner: string;
    endOfLine?: string;
    startOfLine?: string;
  }
}

export const products: ReadonlyArray<Product> = [
  {
    heading: 'Soul Buttons',
    description: ['Soul button is a silhouette drawing of your character, with a color of your choice, something cool as a mouthline which represents something special about your char and a catch phrase, to show you off completely.', 'Its just simple and stylish. Go and get one!'],
    image: '/image/prices/soul-buttons.jpg',
    fullImage: '/image/prices/full-soul-buttons.jpg',
    specialOffer: 'if you order more than one',
    priceConstructor: {
      prices: ['$15', '$13'],
      joiner: ' or ',
      endOfLine: ' ea.',
    }
  },
  {
    heading: 'Telegram Stickers',
    description: ['Fully custom stickers, made personally for you.', '$30 for one, or $25 each if you order 5 or more at once.'],
    image: '/image/prices/telegram-stickers.jpg',
    fullImage: '/image/prices/full-telegram-stickers.jpg',
    specialOffer: 'if you order 5 stickers or more',
    priceConstructor: {
      prices: ['$30', '$25'],
      joiner: ' or ',
      endOfLine: ' ea.',
    }
  },
  {
    heading: 'Icons',
    description: ['Usual icons. Price is for one icon.'],
    image: '/image/prices/icons.jpg',
    fullImage: '/image/prices/full-icons.jpg',
    specialOffer: 'if you order more than one',
    priceConstructor: {
      prices: ['$40', '$30'],
      joiner: ' or ',
      endOfLine: ' ea.',
    }
  },
  {
    heading: 'Badge',
    description: ['Digital badge, you can print yourself.'],
    image: '/image/prices/badge.jpg',
    fullImage: '/image/prices/full-badge.jpg',
    specialOffer: 'if you order more than one',
    priceConstructor: {
      prices: ['$50', '$45'],
      joiner: ' or ',
      endOfLine: ' ea.',
    }
  },
  {
    heading: 'Flat Color Illustrations',
    description: ['Without full shading/rendering.', 'Price is for single character, no special BG.', 'Background price range is $5-$100. SIMPLE TEXTURED BG IS ALWAYS INCLUDED ', 'Artistic freedom - $75 per character Exact idea - $110 per character ', 'Wings and extra limbs are +$20'],
    image: '/image/prices/flat-color-illustrations.jpg',
    fullImage: '/image/prices/full-flat-color-illustrations.jpg',
    specialOffer: 'if you choose artistic freedom',
    priceConstructor: {
      prices: ['$110', '$75'],
      joiner: ' per character or ',
      endOfLine: ' per character',
    }
  },
  {
    heading: 'Full Color Illustrations',
    description: ['Regular commissions.', 'Price is for single character, no special BG.', 'Background price range is $5-$100.', 'SIMPLE TEXTURED BG IS ALWAYS INCLUDED', 'Artistic freedom - $100 per character', 'Exact idea - $150 per character', 'Wings and extra limbs are +$20'],
    image: '/image/prices/full-color-illustrations.jpg',
    fullImage: '/image/prices/full-full-color-illustrations.jpg',
    specialOffer: 'if you choose artistic freedom',
    priceConstructor: {
      prices: ['$150', '$100'],
      joiner: ' per character or ',
      endOfLine: ' per character',
    }
  },
  {
    heading: 'Reference Sheet',
    description: ['Full Reference of your character.', 'It could be as complex as you wish.', 'Every fullbody: 100$', 'Every headshot: 30$', 'Adding clothes and accessories: 5$ - 60$ (Depens on complexity)', 'Add feral/chibi fullbody: 70$'],
    image: '/image/prices/reference-sheet.jpg',
    fullImage: '/image/prices/full-reference-sheet.jpg',
    priceConstructor: {
      prices: ['$100', '$∞'],
      joiner: ' - ',
      endOfLine: '',
      startOfLine: 'Price range: ',
    }
  },
  {
    heading: 'Dakimakura Pillow',
    description: ['$200 for each side (fullbody softshaded)', '+ $15 (to each side) if character has wings', 'Simple Background is going for free.', 'What you get:', 'Printing ready digital PNG files', '2953 x 8858 pixels (Standart printing size for Dakimakuras)', '!IT IS ONLY DIGITAL DRAWING!', '!PILLOW CASE OR PILLOW ITSELF IS NOT INCLUDED IN THIS PRICE!'],
    image: '/image/prices/dakimaru.jpg',
    fullImage: '/image/prices/full-dakimaru.jpg',
    priceConstructor: {
      prices: ['$200', '$430'],
      joiner: ' - ',
      endOfLine: ' ',
      startOfLine: 'Price range: ',
    }
  },
];