import HolyGrail from "components/common/layout/holy-grail/holy-grail";
import { tosList } from "const/tos";
import style from "./tos-page.module.css";

const TosPage = () => {
  return (
    <HolyGrail>
      <h1 className={style["page-heading"]}>TOS</h1>
      {tosList.map((section) => {
        return (
          <section className={style["tos-section"]} key={section.sectionTitle}>
            <h2 className={style["tos-section__heading"]}>
              {section.sectionTitle}
            </h2>
            <ul>
              {section.line.map((line) => {
                return (
                  <li key={line} className={style["tos-section__item"]}>
                    {line}
                  </li>
                );
              })}
            </ul>
          </section>
        );
      })}
    </HolyGrail>
  );
};

export default TosPage;
